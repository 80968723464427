<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>description</md-icon>
                </div>
                <h4 class="title">Edit KF Global Hall Docent</h4>
                </md-card-header>
                <md-card-content>
                    <EditDocent :docent="docent" />
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>info</md-icon>
                </div>
                <h4 class="title">Edit KF Global Hall Info</h4>
                </md-card-header>
                <md-card-content>
                <md-table v-model="informContents" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
                        <md-table-cell md-label="Thumbnail">
                            <img class="item-thumb" :src="item.thumbnailUrl" />
                        </md-table-cell>
                        <md-table-cell md-label="Data Type">{{ item.dataType }}</md-table-cell>
                        <md-table-cell md-label="Title">{{ item.dataTitle }}</md-table-cell>
                        <md-table-cell md-label="Language">{{
                            item.subLang.eng ? 'KO/EN' : 'KO'
                        }}</md-table-cell>
                        <md-table-cell md-label="Edit">
                            <a href="javascript:void(0);" @click="goToEdit(item.sceneId, item.number)" class="editBtn">edit</a>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>public</md-icon>
                </div>
                <h4 class="title">Edit KF Global Hall Media</h4>
                </md-card-header>
                <md-card-content>
                <md-table v-model="contents" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Num">{{ item.number - 3 }}</md-table-cell>
                        <md-table-cell md-label="Thumbnail">
                            <img class="item-thumb" :src="item.thumbnailUrl" />
                        </md-table-cell>
                        <md-table-cell md-label="Data Type">{{ item.dataType }}</md-table-cell>
                        <md-table-cell md-label="Title">{{ item.dataTitle }}</md-table-cell>
                        <md-table-cell md-label="Language">{{
                            item.subLang.eng ? 'KO/EN' : 'KO'
                        }}</md-table-cell>
                        <md-table-cell md-label="Edit">
                            <a href="javascript:void(0);" @click="goToEdit(item.sceneId, item.number)" class="editBtn">edit</a>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
import EditDocent from "@/components/Docent/EditDocent.vue";

export default {
    components: {
        EditDocent
    },
    data() {
        return {
            informContents: {},
            contents: {},
            docent: {},
            type: ["프로그램 1", "프로그램 2", "프로그램 3", "프로그램 4"]
        }
    },
    async created() {
        const res = await axios.get(api.GET_DATA("lobby"));
        if(!res.data.success) {
            return;
        }

        if(res.data.data.contents.length > 4) {
            this.informContents = res.data.data.contents.splice(0, 4);
            
            this.informContents.forEach((element, i) => {
                element.type = this.type[i];
            });
        }

        console.log(this.informContents)
        this.contents = res.data.data.contents;

        this.docent = res.data.data.docent;
    },
    methods: {
        goToEdit(sceneId, number) {
            this.$router.push(`scene/${sceneId}/${number}`);
        }
    }
}
</script>

<style scoped>
.item-thumb {
    max-width: 50px;
}
</style>